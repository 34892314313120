body {
  overflow: hidden;
}

.pageWrapper {
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-image: url("../../Assets/stars.jpg");
  overflow: hidden;

  .title {
    color: white;
    font-size: 7rem;
    margin-bottom: 2rem;
    text-align: center;
  }

  .btn {
    width: 50%;
    padding: 1rem;
    font-size: 3rem;
    font-weight: bold;
    border-radius: 25px;
    cursor: pointer;
    margin: 0.5rem;
  }
}

.volumeModal {
  position: absolute;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(rgba(black, 0.8), rgba(black, 0.8));

  .modalTitle {
    color: white;
    font-size: 4rem;
    max-width: 100vw;
  }

  .modalSubTitle {
    color: white;
    font-size: 3rem;
  }

  .modalMobile {
    display: none;
  }

  .btn {
    border: 2px solid black;
  }

  .yesBtn {
    background: linear-gradient(
      to right,
      rgb(79, 156, 79),
      lightgreen,
      rgb(79, 156, 79)
    );
  }

  .noBtn {
    background: linear-gradient(
      to right,
      rgb(255, 0, 0),
      rgb(252, 94, 94),
      red
    );
  }

  .btn:hover {
    border: 2px solid white;
    transition: border 0.25s;
  }
}

@media only screen and (max-device-height: 576px) and (orientation: landscape) {
  .pageWrapper {
    .btn {
      width: 75%;
      font-size: 2rem;
      margin: 0.2rem;
      padding: 0.5rem;
    }

    .title {
      margin-bottom: 1rem;
      font-size: 4rem;
    }
  }
  .volumeModal {
    text-align: center;
    .modalTitle {
      font-size: 2rem;
    }

    .modalSubTitle {
      font-size: 1rem;
    }

    .modalMobile {
      display: block;
    }
  }
}

@media only screen and (min-device-height: 577px) and (max-device-height: 768px) and (orientation: landscape) {
  .pageWrapper {
    .btn {
      font-size: 2.5rem;
      margin: 0.5rem;
      width: 75%;
    }

    .title {
      font-size: 5.5rem;
      margin-bottom: 1rem;
    }

    .volumeModal {
      text-align: center;
      .modalTitle {
        font-size: 3rem;
      }

      .modalSubTitle {
        font-size: 2rem;
      }
    }
  }
}
