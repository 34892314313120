body {
  overflow: hidden;
}

.entriesWrapper {
  display: flex;
}

.leaderboardEntry {
  color: white;
  display: flex;
  font-size: 1.75rem;
  font-weight: bolder;
  margin: 0 1rem;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  max-width: 100vw;
  overflow: hidden;
}

.title {
  color: white;
  font-size: 6rem;
  text-decoration: underline;
  position: relative;
  text-align: center;
}

.leaderboardWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../../Assets/stars.jpg");
  height: 100vh;
}

.homeBtn {
  width: 70%;
  font-size: 2rem;
  border-radius: 25px;
  align-self: center;
  cursor: pointer;
  margin: 1rem;
  transition: width 0.15s linear;
}

.homeBtn:hover {
  width: 100%;
}

@media only screen and (max-device-height: 576px) and (orientation: landscape) {
  .title {
    font-size: 3.5rem;
  }
  .leaderboardEntry {
    font-size: 1rem;
    margin: 0 1rem;
  }

  .homeBtn {
    width: 100%;
    font-size: 1.8rem;
    margin: 0.5rem;
  }
}

@media only screen and (min-device-height: 577px) and (max-device-height: 768px) and (orientation: landscape) {
}
