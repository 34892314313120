.highscoreWrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  .title {
    color: white;
    text-align: center;
    font-size: 5rem;
    width: 100vw;
  }
  .highscoreForm {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .formInput {
    width: 50%;
    background-color: transparent;
    border: none;
    color: white;
    outline: none;
    font-size: 8rem;
    letter-spacing: 1rem;
    text-align: center;
    text-transform: uppercase;
    &::placeholder {
      opacity: 1;
      color: white;
    }
  }
  .formBtn {
    width: 60%;
    font-size: 2rem;
    border-radius: 25px;
    transition: width 0.15s linear;
    cursor: pointer;

    .spinner {
      position: relative;
    }
  }
  .score {
    color: white;
  }
}

.formError {
  color: white;
}

@media only screen and (max-device-height: 576px) and (orientation: landscape) {
  .highscoreWrapper {
    .title {
      font-size: 3rem;
    }

    .formInput {
      font-size: 4.5rem;
    }
  }
}

@media only screen and (min-device-height: 577px) and (max-device-height: 768px) and (orientation: landscape) {
  .highscoreWrapper {
    .title {
      font-size: 4rem;
    }

    .formInput {
      font-size: 5.5rem;
    }
  }
}
