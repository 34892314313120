.spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: 5rem;
  font-weight: bold;
  animation: RotateSpinner 1s linear infinite;
}

@media only screen and (max-device-height: 576px) and (orientation: landscape) {
  .spinner {
    font-size: 4rem;
  }
}

@keyframes RotateSpinner {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
