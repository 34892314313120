.modalWrapper {
  width: 100vw;
  height: 100vh;
  background: rgba(white, 0.7);
  position: fixed;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  display: none;
}

.modalTitle {
  color: black;
  text-align: center;
  font-size: 2rem;
}

@media only screen and (orientation: portrait) {
  .modalWrapper {
    display: flex;
  }
}
