@import url("https://fonts.googleapis.com/css2?family=Space+Mono:wght@700&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Space Mono", monospace;
}

body {
  background-image: url("./Assets/stars.jpg");
}
