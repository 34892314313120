.enemy {
  position: absolute;
  user-select: none;
  -webkit-user-drag: none;
  pointer-events: fill;
  top: 100%;
  width: 5%;
  height: 9%;
  background-size: contain;
  background-repeat: no-repeat;
  transform: rotate(180deg);
  z-index: 1;
  animation-fill-mode: forwards;

  &.enemy0 {
    animation: MoveEnemyDown 6s linear;
  }
  &.enemy1 {
    animation: MoveEnemyDown 5.5s linear;
  }
  &.enemy2 {
    animation: MoveEnemyDown 5s linear;
  }
  &.enemy3 {
    animation: MoveEnemyDown 4.5s linear;
  }
  &.enemy4 {
    animation: MoveEnemyDown 4s linear;
  }
  &.enemy6 {
    animation: MoveEnemyDown 10s linear;
  }
}

.healthWrapper {
  width: 60vw;
  height: 1rem;
  border: 2px solid white;
  border-radius: 25px;
  overflow: hidden;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);

  .healthBar {
    height: 100%;
    background-color: red;
  }
}

.bossName {
  color: white;
  text-align: center;
  font-size: 3rem;
  pointer-events: none;
  user-select: none;
}

@media only screen and (max-device-height: 576px) and (orientation: landscape) {
  .healthWrapper {
    height: 0.65rem;
    width: 50vw;
  }

  .bossName {
    font-size: 1.5rem;
  }

  .enemy {
    &.enemy0 {
      animation: MoveEnemyDown calc(6s / 1.5) linear;
    }
    &.enemy1 {
      animation: MoveEnemyDown calc(5.5s / 1.5) linear;
    }
    &.enemy2 {
      animation: MoveEnemyDown calc(5s / 1.5) linear;
    }
    &.enemy3 {
      animation: MoveEnemyDown calc(4.5s / 1.5) linear;
    }
    &.enemy4 {
      animation: MoveEnemyDown calc(4s / 1.5) linear;
    }
    &.enemy6 {
      animation: MoveEnemyDown calc(10s / 1.5) linear;
    }
  }
}

@media only screen and (min-device-height: 577px) and (max-device-height: 768px) and (orientation: landscape) {
  .enemy {
    &.enemy0 {
      animation: MoveEnemyDown calc(6s / 1.4) linear;
    }
    &.enemy1 {
      animation: MoveEnemyDown calc(5.5s / 1.4) linear;
    }
    &.enemy2 {
      animation: MoveEnemyDown calc(5s / 1.4) linear;
    }
    &.enemy3 {
      animation: MoveEnemyDown calc(4.5s / 1.4) linear;
    }
    &.enemy4 {
      animation: MoveEnemyDown calc(4s / 1.4) linear;
    }
    &.enemy6 {
      animation: MoveEnemyDown calc(10s / 1.4) linear;
    }
  }
}

@keyframes MoveEnemyDown {
  0% {
    top: -10%;
  }
  100% {
    top: 100%;
  }
}
