.gameWrapper {
  cursor: url("../../Assets/crosshair.png") 60 60, auto;
  height: 100vh;
  width: 100vw;
  position: relative;
  background-image: url("../../Assets/stars.jpg");
  overflow: hidden;
}

.waitingWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.waitingTxt {
  color: white;
  width: 100vw;
  font-size: 5rem;
  text-align: center;
}

.spacer {
  height: 10rem;
}

.waitingBtn {
  width: 30%;
  font-size: 3rem;
  border-radius: 25px;
  cursor: pointer;
  transition: width 0.15s ease;
}

.waitingBtn:hover {
  width: 50%;
}

.round {
  color: white;
  font-size: 5rem;
  position: absolute;
  top: 50%;
  right: 125%;
  transform: translate(0, -50%);
  text-align: center;
  text-wrap: nowrap;
  user-select: none;
}

.showRound {
  animation: RoundChange 2s linear;
}

.player {
  width: 10%;
  height: 10rem;
  position: absolute;
  left: 50%;
  bottom: -3rem;
  user-select: none;
  transform-origin: left center;
  transform: translate(-50%, 0);
}

.gameOver {
  font-size: 3rem;
  color: white;
  display: none;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  &.active {
    display: block;
  }
  button {
    width: 50%;
    font-size: 2rem;
    border-radius: 25px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
  }

  .btnCover {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 0;
  }

  .playBtn .btnCover {
    background: linear-gradient(rgb(223, 93, 93), rgb(164, 68, 68));
  }

  .homeBtn .btnCover {
    background: linear-gradient(rgb(102, 205, 102), rgb(37, 150, 37));
  }
  button .txt {
    position: relative;
    z-index: 2;
  }
  button:hover {
    .btnCover {
      animation: MoveBtnLeft 0.8s linear infinite;
    }
  }
}

.bombWrapper {
  position: absolute;
  animation: moveBomb 5s linear;
  user-select: none;
  z-index: 0;
  width: 5%;
  height: 9%;
}

.bomb {
  width: 100%;
  height: 100%;
  animation-fill-mode: forwards;
}

@media only screen and (max-device-height: 576px) and (orientation: landscape) {
  .player {
    width: 5%;
    height: 6rem;
  }

  .gameOver {
    font-size: 1.8rem;

    button {
      font-size: 1.2rem;
    }
  }

  .waitingTxt {
    font-size: 3rem;
  }

  .spacer {
    height: 9rem;
  }

  .waitingBtn {
    font-size: 2rem;
  }

  .bombWrapper {
    animation: moveBomb calc(5s / 1.5) linear;
  }
}

@media only screen and (min-device-height: 577px) and (max-device-height: 768px) and (orientation: landscape) {
  .gameOver {
    font-size: 2.3rem;

    button {
      font-size: 1.7rem;
    }
  }

  .waitingTxt {
    font-size: 4rem;
  }
}

@keyframes MoveBtnLeft {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}

@keyframes RoundChange {
  0% {
    right: 100%;
  }
  15% {
    right: 75%;
  }
  85% {
    right: 25%;
  }
  100% {
    right: -25%;
  }
}

@keyframes moveBomb {
  from {
    top: 0%;
  }
  to {
    top: 100%;
  }
}
