.icon {
  position: absolute;
  top: 0;
  right: 0;
  color: white;
  font-size: 2rem;
  margin-right: 0.5rem;
  margin-top: 0.5rem;
  cursor: pointer;
  z-index: 4;
}
