.objectiveWrapper {
  display: flex;
  color: white;
  align-items: center;
  flex-direction: column;
  background-image: url("../../Assets/stars.jpg");
  height: 100vh;
  max-height: 100vh;
  overflow: auto;
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: transparent transparent; /* For Firefox */
  -ms-overflow-style: none; /* For IE 10+ */
  scrollbar-width: none;
}

.objectiveWrapper::-webkit-scrollbar {
  width: 6px;
}

.objectiveWrapper::-webkit-scrollbar-track {
  background-color: transparent;
}

.objectiveWrapper::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 3px;
}

.title {
  text-align: center;
  margin: 1rem;
  font-size: 7rem;
}

.descriptionTxt {
  width: 95vw;
  font-size: 2rem;
  text-align: center;
}

.txtWrapper {
  width: 95vw;
  display: flex;

  .txtSection {
    margin: 1rem 0;
    font-size: 1.5rem;
  }

  .txtTitle {
    text-align: center;
    font-size: 2.5rem;
  }

  .leftTxt {
    width: 50%;
    padding: 0.5rem;
  }

  .rightTxt {
    width: 50%;
    padding: 0.5rem;
  }
}

.homeBtn {
  width: 70%;
  font-size: 3rem;
  border-radius: 25px;
  align-self: center;
  cursor: pointer;
  margin: 1rem;
  transition: width 0.15s linear;
}

.homeBtn:hover {
  width: 90vw;
}

@media only screen and (max-device-height: 576px) and (orientation: landscape) {
  .objectiveWrapper {
    justify-content: flex-start;
  }

  .title {
    font-size: 4rem;
  }

  .descriptionTxt {
    font-size: 1.5rem;
  }

  .txtWrapper {
    .txtSection {
      font-size: 1rem;
    }
  }

  .homeBtn {
    font-size: 2rem;
  }
}

@media only screen and (min-device-height: 577px) and (max-device-height: 768px) and (orientation: landscape) {
  .title {
    font-size: 5rem;
  }

  .descriptionTxt {
    font-size: 2rem;
  }

  .txtWrapper {
    .txtSection {
      font-size: 1.25rem;
    }
  }
}
