.GUINav {
  color: white;
  font-size: 1.6rem;
  pointer-events: none;
  user-select: none;
  display: flex;
  justify-content: space-between;
  background-color: rgb(3, 0, 3);
  max-height: 3rem;
  z-index: 3;
  position: relative;

  .livesWrapper {
    margin-right: -4rem;
    display: flex;
  }
}

@media only screen and (max-device-height: 576px) and (orientation: landscape) {
  .GUINav {
    font-size: 1rem;
    max-height: 2rem;
  }
}

@media only screen and (min-device-height: 577px) and (max-device-height: 768px) and (orientation: landscape) {
  .GUINav {
    font-size: 1.3rem;
    max-height: 2.5rem;
  }
}
